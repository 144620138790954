import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Grid } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import PhotoIcon from '@material-ui/icons/Photo';
import ErrorIcon from '@material-ui/icons/Error';

import { API_DELIVERY_FORM, API_ASSEMBLY_PHOTO } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import DialogPhoto from '../DialogPhoto';
import dayjs from 'dayjs';

class DeliveryForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: true,
            instances: [],
            assemblies: [],
            selectedAssemblies: [],
            errors: {},

            photoPreviewUrl: null,
            photoPreviewTitle: null,
        };        
    }


    componentDidMount = () => {
        loggedUserServerFetch (API_DELIVERY_FORM, 'get', {}, {}, {}).then ( (response) => {
            const instances = response?.data?.instance || [];
            const assemblies = response?.data?.assembly || [];

            if (! instances || instances.length === 0) {
                toast.error ('Debe dar de alta al menos una instancia de TLS', 'err_instance');
                this.props.onClose();

            } else if (! assemblies || assemblies.length === 0) {
                toast.error ('No hay ensamblajes pendientes de entrega', 'err_assemblies');
                this.props.onClose();
            }

            this.setState({...this.state, instances, assemblies, loading: false});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario', 'err_comm');
            this.props.onClose();
        });
    }

    buildSchema = (form) => {

        const handleDisplayPhoto = this.handleDisplayPhoto;

        //Instancias y cuadrillas
        let instanceOptions = {};
        let crewOptions = {};
        const instances = (this.state.instances || []);
        instances.forEach (instance => {
            instanceOptions[instance.id] = instance.description;

            if (form.instance && parseInt(form.instance) === instance.id)
                crewOptions[-1] = '';
                (instance.crews || []).forEach( crew => {
                    crewOptions[crew.id] = crew.description;
                })

        });

        //Equipos
        const devices = (this.state.assemblies || []).map (assembly => {
            return ({
                id: assembly.id, 
                mac: assembly.rtu?.mac, 
                imei: assembly.imei, 
                fw: assembly.fw_ver, 
                created_at: assembly.created_at,
                user: assembly.user?.name,
                hasError: !!this.state.errors[assembly.id],
                errorDescription: this.state.errors[assembly.id] || 'Error desconocido',
            })
        });


        const schema =  {
            fields: [
                {
                    key: 'instance',
                    name: 'instance',
                    type: 'select',
                    label: 'Cliente',
                    required: true,
                    disabled: false,
                    autoFocus: true,
                    options: instanceOptions,
                },

                {                    
                    key: 'crew',
                    name: 'crew',
                    type: 'select',
                    label: 'Cuadrilla',
                    required: false,
                    disabled: (! form?.instance ),                    
                    options: crewOptions,
                },

                {
                    key: 'assemblies',
                    name: 'assemblies',
                    type: 'table-selector',
                    label: 'Equipos',
                    elements: [],
                    elementIdField: 'id',
                    options: devices,
                    columns: [
                        { title: 'MAC', field: 'mac'},
                        { title: 'Versión firmware', field: 'fw' },
                        { title: 'IMEI', field: 'imei', type: 'numeric' },
                        { title: 'Usuario', field: 'user'},
                        { 
                            title: 'Fecha y hora de ensamblaje', 
                            field: 'createdAt', 
                            render: (element) => dayjs(element.created_at).format('DD/MM/YYYY HH:mm'),
                            customSort: (a,b) => {
                                return dayjs(a.created_at).unix() - dayjs(b.created_at).unix()
                            },
                        },
                        { title: 'Action', sorting: false, searchable: false, type: 'numeric', render: (element) => {
                            const items = [];
                            const hasError = this?.state?.errors[element.mac]||false;

                            if (hasError) {
                                items.push (
                                    <Tooltip title={hasError}>
                                        <IconButton 
                                            style={{color:red[500]}}   
                                        >
                                            <ErrorIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                );                    
                            }
            
                            items.push(
                                <IconButton aria-label="Ver foto" onClick={ () => handleDisplayPhoto (element) }
                                    ><PhotoIcon fontSize="small" />
                                </IconButton>
                            );
                            return items;
                        }}
            
                    ]
                }
            ]};

        return schema;
    }

    handleDisplayPhoto = (element) => {
        const url = API_ASSEMBLY_PHOTO.replace('{$id}', element.id);
        this.setState({...this.state, photoPreviewUrl: url, photoPreviewTitle:`Foto del ensamblaje de la remota ${element.mac}`});
    }

    handleCloseDisplayPhoto = () => {
        this.setState({...this.state, photoPreviewUrl: null, photoPreviewTitle: null});
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    processErrorSubmit = (response, error) => {
        const data = response?.data?.result || {};
        let errors = {};        
        (data.exists || []).forEach ( e => errors[e] = 'Ya existe una RTU con esta MAC en la instancia de destino');
        (data.error || []).forEach ( (e,i) => errors[e] = data.error_messages[i] || 'Error desconocido');

        this.setState ({...this.state, errors});
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.loading) return this.renderLoading();

        return (
            <AbstractForm 
                schema={schema}
                onSubmitError={this.processErrorSubmit} 
                {...this.props}
            >
                <DialogPhoto
                    open={!! this.state.photoPreviewUrl}
                    onClose={this.handleCloseDisplayPhoto} 
                    title={this.state.photoPreviewTitle}
                    url={this.state.photoPreviewUrl}
                />
            </AbstractForm>)
    }
}

export default DeliveryForm;